import React, { useState } from 'react'

interface Props {
    email: string
    number?: boolean
}

const Email = ({ email, number }: Props) => {
    const [show, setShow] = useState(false)

    return (
        <button
            className={`${
                !show &&
                `border-2 px-4 w-56 text-black bg-white  hover:text-white hover:bg-black mt-4`
            } border-white inline-block  py-3 text-center mt-2 transition duration-500`}
            onClick={() => setShow(true)}
        >
            {!show && (
                <span>
                    {number ? (
                        <>click to show phone</>
                    ) : (
                        <>click to show email</>
                    )}
                </span>
            )}
            {show && (
                <a
                    className="text-gradient-4 text-lg"
                    href={!number ? `mailto: ${email}` : `tel: ${email}`}
                >
                    {email}
                </a>
            )}
        </button>
    )
}

export default Email
