import { Link } from 'gatsby'
import React, { ReactElement } from 'react'
import { animated, useSpring } from 'react-spring'
import Brand from '../../components/Brand'
import Breadcrumbs from '../../components/Breadcrumbs'
import Email from '../../components/Email'
import Layout from '../../components/Layout'
import SEO from '../../components/Seo'
import text from '../../components/Text/text'

const breadcrumbs = [
    {
        link: '/',
        title: 'Home',
    },
    {
        link: '/careers',
        title: 'Careers',
    },
    {
        link: null,
        title: 'Java Developer',
    },
]

const JobJava = (): ReactElement => {
    const animation = useSpring({
        delay: 700,
        opacity: 1,
        transform: 'translate3d(0,0px,0)',
        from: { opacity: 0, transform: 'translate3d(0,30px,0)' },
    })
    return (
        <>
            <SEO title="Java Developer - Careers" />
            <Layout>
                <animated.div
                    style={animation}
                    className="w-full max-w-3xl mx-auto text-white mb-20"
                >
                    <div className="flex px-4 py-4 md:py-8 text-white">
                        <Link to="/">
                            <Brand />
                        </Link>
                    </div>
                    <div className="p-4 overflow-hidden">
                        <Breadcrumbs steps={breadcrumbs} />
                        <h1 className={text.H1}>Java Developer</h1>
                        <p className="text-lg mt-4 opacity-75">
                            We are looking for experienced, skilled and
                            passionate Java developers to drive our internal and
                            external project portfolio. Prior Spring and Unit
                            testing is a must have. This job would be fully
                            remote at this point due to current circumstances.
                        </p>
                        <h2 className={text.H2}>Responsibilities</h2>
                        <ul className={text.UL}>
                            <li className={text.LI_2}>
                                Writing well designed, testable, efficient code
                                by using best software development practices
                                (DRY, SOLID)
                            </li>
                            <li className={text.LI_2}>
                                Developing dockerized microservices using Spring
                                framework
                            </li>
                            <li className={text.LI_2}>
                                Integrating data from various back-end services
                                and databases (RESTful api)
                            </li>
                            <li className={text.LI_2}>
                                Gather and refine specifications and
                                requirements based on technical needs
                            </li>
                            <li className={text.LI_2}>
                                Create and maintain software documentation
                            </li>
                            <li className={text.LI_2}>
                                Be responsible for maintaining, expanding, and
                                scaling applications
                            </li>
                            <li className={text.LI_2}>
                                Stay plugged into emerging technologies/industry
                                trends and apply them into operations and
                                activities
                            </li>
                            <li className={text.LI_2}>
                                Writing well tested code (ideally TDD)
                            </li>
                            <li className={text.LI_2}>
                                Ability to work within Agile/Scrum flow.
                            </li>
                        </ul>
                        <h2 className={text.H2}>Requirements</h2>
                        <ul className={text.UL}>
                            <li className={text.LI_2}>
                                Proven working experience in Java programming
                            </li>
                            <li className={text.LI_2}>
                                Top-notch programming skills/patterns and
                                development workflows as well as in-depth
                                knowledge of modern development stacks
                            </li>
                            <li className={text.LI_2}>
                                Familiarity with the microservices, third party
                                api integrations and docker
                            </li>
                            <li className={text.LI_2}>
                                A solid understanding of how server applications
                                work including security, session management, and
                                best development practices
                            </li>
                            <li className={text.LI_2}>
                                Adequate knowledge of relational database
                                systems,
                            </li>
                            <li className={text.LI_2}>
                                Object Oriented Programming and web application
                                development
                            </li>
                            <li className={text.LI_2}>
                                Hands-on experience with network diagnostics,
                                network analytics tools
                            </li>
                            <li className={text.LI_2}>
                                Knowledge of Agile workflow
                            </li>
                            <li className={text.LI_2}>
                                Aggressive problem diagnosis and creative
                                problem solving skills
                            </li>
                            <li className={text.LI_2}>
                                Strong organizational skills to juggle multiple
                                tasks within the constraints of timelines and
                                budgets with business acumen
                            </li>
                            <li className={text.LI_2}>
                                Ability to work and thrive in a fast-paced
                                environment, learn rapidly and master diverse
                                web technologies and techniques.
                            </li>
                            <li className={text.LI_2}>
                                Demonstrable knowledge of Unit and functional
                                test frameworks
                            </li>
                            <li className={text.LI_2}>
                                Proven working experience in Java programming
                            </li>
                        </ul>
                        <h2 className={text.H2}>Nice to have</h2>
                        <p className={text.P_2}>
                            A degree in CS, solid english, public repository,
                            open source contribution, knowledge of server
                            systems and dev tools, knowledge of continuous
                            integration/deployment platform/flow.
                        </p>
                        <h2 className={text.H2}>We Offer</h2>
                        <li className={text.LI_2}>Competitive salary</li>
                        <li className={text.LI_2}>
                            Work on exciting and challenging projects
                        </li>
                        <li className={text.LI_2}>Startup environment</li>
                        <li className={text.LI_2}>Fully Agile team</li>
                        <li className={text.LI_2}>
                            Opportunity to grow with us
                        </li>
                        <li className={text.LI_2}>Flexible working time</li>
                        <li className={text.LI_2}>
                            Quarterly integration night outs/getaways.
                        </li>
                        <li className={text.LI_2}>
                            Regular Training and Career Development
                        </li>
                        <h2 className={text.H2}>How to apply</h2>
                        <p className={text.P_2}>
                            Call or send an email (be sure to include your CV
                            too) to:
                        </p>
                        <h4 className={text.H4}>Joanna Biegalska</h4>

                        <Email email="+48 530 310 561" number />
                        <br />
                        <Email email="joanna@plaincoded.pl" />
                    </div>
                </animated.div>
            </Layout>
        </>
    )
}

export default JobJava
